<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="template.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('template.title').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('template.title').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col v-if="isNameVisible" cols="12" md="8" class="pr-0">
              <b-form-group :label="$t('template.field.name')" label-for="name">
                <b-input-group>
                  <b-form-input id="name" type="text"
                    :data-vv-as="$t('template.field.name')"
                    data-vv-name="template.name"
                    data-vv-delay="500"
                    v-model="template.name" 
                    v-validate="{ required: true }"
                    :readonly="isNameReadOnly"
                    trim
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'template.name')">
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('template.name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          
            <b-col v-if="isIdentifierVisible" cols="12" md="4" class="pr-0">
              <b-form-group :label="$t('field.identifier')" label-for="identifier">
                <b-input-group>
                  <b-form-input id="identifier" type="text"
                    :data-vv-as="$t('field.identifier')"
                    data-vv-name="template.identifier"
                    :maxlength="maxIdentifierLength"
                    v-model="template.identifier" 
                    :readonly="isIdentifierReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
        
            <b-col v-for="(field, index) in customFieldsFiltered" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="template[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
            
            <b-col cols="12" class="pr-0" v-if="isDescriptionVisible">
              <b-form-group :label="$t('template.field.description')" label-for="description">
                <b-form-textarea id="description" 
                  :placeholder="isDescriptionReadOnly? '' : $t('template.placeholder.description')"
                  :data-vv-as="$t('template.field.description')"
                  data-vv-name="template.description"
                  data-vv-delay="500"
                  v-model="template.description"
                  :max-rows="6"
                  v-validate="{ max: maxDescriptionLength }"
                  :readonly="isDescriptionReadOnly"
                  trim
                  :rows="3"/>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDescriptionError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('template.description') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          
            <b-col v-if="isTagVisible" cols="12" md="8" class="pr-0">
              <b-form-group>
                <TagList :holderId="id" :tags="tags" @modified="tagsModified" :readOnly="isTagReadOnly"/>
              </b-form-group>
            </b-col>
          
            <b-col v-if="isColorVisible" cols="12" md="4" class="pr-0">
              <div class="color-container">
                <Color :disabled="isColorReadOnly" v-model="template.color" :update="updatedColor"/>
              </div>
            </b-col>
          
            <b-col cols="12" class="pr-0" v-if="isNoteVisible">
              <b-form-group>
                <NoteList :readOnly="isNoteReadOnly" :notes="notes" @add="addNote" @edit="editNote" @toRemove="removeNote" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="canView('TEMPLATE__TASK') && exists" size="sm" variant="secondary" @click="preScheduleRedirect" style="margin-right: auto">
          {{ $t('template.button.edit_tasks') }}
        </b-button>
        <b-button v-if="!isAccessDenied && ((exists && canEdit()) || (!exists && canAdd()))" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('template.confirmation.title_unsaved_change')"
        v-model="state.confirmUnsavedChangeShow"
        @ok="confirmUnsavedChangeOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <p>{{ $t('template.confirmation.unsaved_change') }}</p>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" :disabled="disableOk" variant="success" @click="ok()">{{ $t('button.continue') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="TEMPLATE/PROJECT" :customFields="customFields" links="NOTE,TAG" />
      <NoteModal v-if="state.noteShow" :show.sync="state.noteShow" :note="note" @toAdd="toAddNote" @toUpdate="toUpdateNote"/>
    </template>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { cloneDeep } from 'lodash';
import { persistNotes } from '@/components/Note/script/crud-util';
import { updateTags } from '@/components/Tag/script/crud-util';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { templateProjectService,
         templateProjectLinkTagService } from '@/services';
import { removeDeniedProperties } from '@/views/management/script/common';

export default {
  name: 'TemplateProjectModal',
  components: {
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    TagList: () => import('@/components/Tag/TagList.vue'),
    NoteList: () => import('@/components/Note/NoteList.vue'),
    NoteModal: () => import('@/components/modal/NoteModal.vue'),
    Color: () => import('@/components/Color/Color.vue'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:        { type: String,   default: `TEMPLATE_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'TEMPLATE__PROJECT',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      state: {
        editable:     false,
        isSubmitting: false,
        modalShow:    false,
        confirmUnsavedChangeShow: false,
        historyShow:  false,
        noteShow:            false
      },
      template: {
        uuId:         null,
        name:         null,
        description:  null,
        identifier:   null,
        color:        null,
        readOnly:     false
      },
      tags: [],
      notes: [],
      note: {
        uuId: null,
        text: null,
        identifier: null
      },
      updatedColor: null,
      isAccessDenied: false,
      
      customFields: [],
      restrictedRequiredField: null      
    }
  },
  created() {
    this.getModelInfo();
    this.original = {
      readOnly: false
    }
    this.originAvatarBanner = null;
    this.originTemplate = null;
    this.originTags = [];
    this.originNotes = [];
    if(this.exists) {
      this.templateGet(this.id);
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
  },
  mounted() {
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originAvatarBanner = null;
    this.originTemplate = null;
    this.originTags = null;
    this.originNotes = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `TEMPLATE_PROJECT_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.template.readOnly;
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'template.name');
    },
    showDescriptionError() {
      return fieldValidateUtil.hasError(this.errors, 'template.description');
    },
    exists() {
      return this.id && !this.id.startsWith('TEMPLATE_NEW_') && !this.id.startsWith('TEMPLATE__PROJECT_NEW_');
    },
    labelTitle() {
      return this.title? this.title: this.$t('template.title_new');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    maxDescriptionLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "description";
      });
      return values.length !== 0 ? values[0].max : 10000;
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    isIdentifierVisible() {
      return this.canView(this.permissionName, ['identifier']) && ((!this.exists && this.canAdd(this.permissionName, ['identifier']))
      || this.exists)
    },
    isIdentifierReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['identifier']))
    },
    isDescriptionVisible() {
      return this.canView(this.permissionName, ['description']) && ((!this.exists && this.canAdd(this.permissionName, ['description']))
      || this.exists)
    },
    isDescriptionReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['description']))
    },

    isColorVisible() {
      return this.canView(this.permissionName, ['color']) && ((!this.exists && this.canAdd(this.permissionName, ['color'])) 
      || this.exists)
    },
    isColorReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['color']))
    },
    isTagVisible() {
      //Tag field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('TAG') && this.canView(this.permissionName, ['TAG'])
    },
    isTagReadOnly() {
      return this.isReadOnly || !this.canAdd('TAG') || !this.canEdit('TAG') || !this.canEdit(this.permissionName, ['TAG'])
    },
    isNoteVisible() {
      //Note field is only visible on existing entity. Therefore, skip checking canEdit for new entity creation flow.
      return this.exists && this.canView('NOTE') && this.canView(this.permissionName, ['NOTE'])
    },
    isNoteReadOnly() {
      return this.isReadOnly || !this.canEdit(this.permissionName, ['NOTE'])
    },
    disableOk() {
      return (this.original.readOnly && this.template.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if(newValue != this.state.modalShow) {
        await getCustomFieldInfo(this, 'PROJECT', 'PROJECT_TEMPLATE');
        this.$validator.resume();
        this.state.modalShow = newValue;
        this.alertMsg = null;
        this.notes = [];
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.state.editable = (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        this.restrictedRequiredField = null;
        this.resetTemplateProperties();
        if(this.exists) {
          this.templateGet(this.id);
        } else {
          if (newValue) {
            const requiredFields = ['name']
            const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
            if (requiredCustomFields.length > 0) {
              requiredFields.push(...requiredCustomFields);
            }
            let result = this.canView2(this.permissionName, requiredFields);
            if (result.val) {
              result = this.canAdd2(this.permissionName, requiredFields)
            } 
            
            if (result.restrictedProp != null) {
              this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isAccessDenied = false;
            } else {
              this.isAccessDenied = true;
            }
            
          } else {
            this.isAccessDenied = false;
          }

          // if (newValue && 
          //   !(this.canView(this.permissionName, ['name'])
          //     && this.canAdd(this.permissionName, ['name'])
          // )) {
          //   this.isAccessDenied = true;
          // } else {
          //   this.isAccessDenied = false;
          // }
        }
      }
    },
    getDisplayNameOfProperty(val) {
        const found = this.customFields.find(i => i.name == val);
        if (found != null) {
          return found.displayName;
        }
        return  this.$t(`template.field.${val}`);
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "PROJECT_TEMPLATE"}).then(value => {
        self.modelInfo = value.PROJECT_TEMPLATE.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    floatFormatter(value) {
      return value && !isNaN(value)? parseFloat(value).toFixed(2): 0.00;
    },
    templateGet(id) {
      templateProjectService.get([{ uuId: id}], ['TAG', 'NOTE']).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
          return;
        }
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      const p = this.template;
      for (const key of Object.keys(p)) {
        p[key] = data[key] != null? data[key] : null;
      }

      this.original.readOnly = data.readOnly;

      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          p[field.name] = data[field.name];
        }
      }
      
      p.uuId = data.uuId;
      this.originTemplate = cloneDeep(p);
      
       //Setup Comment data
      this.notes = typeof data.noteList !== 'undefined' ? data.noteList : [];
      this.notes.sort((a, b) => {
        return b.modified - a.modified;
      });
      this.originNotes = cloneDeep(this.notes);
      if (data.noteList && data.noteList.length > 0) {
        const container = this.$refs['comments'];
        if (typeof container !== 'undefined') {
          container.scrollTop = container.scrollHeight;
        }
      }
      
      //Setup Tags data
      if (data.tagList && data.tagList.length > 0) {
        const list = typeof data.tagList !== 'undefined' ? data.tagList : [];
        this.originTags.splice(0, this.originTags.length, ...list);
        this.tags.splice(0, this.tags.length, ...cloneDeep(list));
      } else {
        this.originTags.splice(0, this.originTags.length);
        this.tags.splice(0, this.tags.length);
      }
      
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.template[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.templateSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    templateSubmit() {
      const data = cloneDeep(this.template);
      let method = 'update';
      if(!this.exists) {
        delete data['uuId'];
        data.durationAUM = 'D'; //Required by template project Gantt component and server doesn't provide default value at the moment. So hard code it here. Subject to change in future.
        data.autoScheduling = true; //Required by template project Gantt component and server doesn't provide default value at the moment.
        method = 'create';
      }
      this.templatePost(method, data);
    },
    async templatePost(method, data) {
      this.state.isSubmitting = true;
      //Skip updating template if there is no change in template properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedTemplateProperties(data);
      }

      if (method == 'create' || hasChanged) {
        removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
        const result = await this.updateTemplate(method, data);
        if(result.hasError) {
          this.alertMsg = result.msg;
          this.state.isSubmitting = false;
          return;
        }
        
        if (method === 'create') {
          data.uuId = result.templateId;
        }
      }

      let hasError = false;

      // save the color in the profile
      this.updatedColor = data.color;
      if (!this.isTagReadOnly) {
        const tagResult = await updateTags(data.uuId, templateProjectLinkTagService, this.originTags, this.tags);
        if (tagResult.hasError) {
          hasError = true;
          this.alertMsg = this.$t(`template.${method}_partial`);
          if (tagResult.errors.filter(i => i.response != null && i.response.status == 403).length > 0) {
            this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('tag.title').toLowerCase()]))
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('tag.title').toLowerCase()]))
          }
        }
      }
      
      //Notes
      if (!this.isNoteReadOnly) {
        //Remove uuId of new notes before saving
        const notes = cloneDeep(this.notes);
        for (let i = 0, len = notes.length; i < len; i++) {
          if (notes[i].uuId != null && notes[i].uuId.startsWith('NEW_NOTE')) {
            delete notes[i].uuId;
          }
        }
        const noteResult = await persistNotes(data.uuId, this.originNotes, notes);
        if (noteResult.errors.length > 0 || noteResult.errorCodes.length > 0) {
          hasError = true;
          this.alertMsg = this.$t(`template.${method}_partial`);
          if (noteResult.errorCodes.length > 0) {
            if (noteResult.errorCodes.includes(403)) {
              this.alertMsgDetails.push(this.$t('error.insufficient_permission_to_update', [this.$t('notes').toLowerCase()]))
            } else {
              this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
            }
          } else {
            this.alertMsgDetails.push(this.$t('error.unable_to_update', [this.$t('notes').toLowerCase()]))
          }
        }
      }
      
      
      this.state.isSubmitting = false;
      if (!hasError) {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`template.${method}`) });
      }
    },
    async updateTemplate(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`template.${method}`)
      }
      let templateId = await templateProjectService[method]([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.msg = this.$t(`template.error.failed_to_${method}`);
        const response = e.response;
        if (response && 422 === response.status) {
          const feedback = response.data[response.data.jobCase][0];
          const clue = feedback.clue.trim().toLowerCase();
          if(['missing_argument','cannot_be_blank', 
              'string_limit_exceeded', 'number_limit_exceeded',
              'date_limit_exceeded'].includes(clue)) {
            result.msg = this.$t('error.attention_required');
            const fieldKey = `template.${feedback.args[0]}`;
            const args = [this.$t(`template.field.${feedback.args[0]}`)];
            let clueNotHandled = false;
            switch (clue) {
              case 'missing_argument': //Do nothing. Doesn't need additional argument
              case 'cannot_be_blank':
                break;
              case 'date_limit_exceeded':
                args.push(moment.utc(feedback.args[1]).format('YYYY-MM-DD'));
                break;
              case 'string_limit_exceeded':
              case 'number_limit_exceeded':
                args.push(feedback.args[1]);
                break;
              default:
                clueNotHandled = true;
                result.msg = this.$('error.internal_server'); //reset the msg to internal_server error.
            }
            if (!clueNotHandled) {
              this.errors.add({
                field: fieldKey,
                msg: this.$t(`error.${clue}`, args)
              });
            }
          }
        }
      });
      result.templateId = templateId;
      return result;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.errors.add({
            field: `template.${feedback.spot}`,
            msg: this.$t(`error.${feedback.clue}`, feedback.args? feedback.args: [])
          })
        } else {
          console.error(e); // eslint-disable-line no-console
          this.alertMsg = this.$t('error.internal_server');
        }
      } else {
        console.error(e); // eslint-disable-line no-console
        this.alertMsg = this.$t('error.internal_server');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetTemplateProperties() {
      const keys = Object.keys(this.template);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        if(keys[i] === 'uuId') {
          continue;
        }
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.template[keys[i]] = customField.def;
            continue;
          }
        }
        this.template[keys[i]] = null;
      }

      this.originAvatarBanner = null;
      this.originTemplate = null;
      this.originNotes = [];
      this.notes = [];
    },
    confirmUnsavedChangeOk() {
      this.state.confirmUnsavedChangeShow = false;
    },
    scheduleRedirect() {
      this.$store.dispatch("breadcrumb/update", this.template.name, { root: true });
      this.$router.push(`/manage/template/tasks/${this.template.uuId}`);
    },
    preScheduleRedirect() {
      //0. Check if unsaved data
      //1. yes, ask if to proceed without saving.
      //1.1 yes, redirect.
      //2. no, do nothing. 

      const checkDataChange = () => {
        const originTemplate = this.originTemplate;
        const template = this.template;

        let isChanged = false;
        for(const key in originTemplate) {
          if(originTemplate[key] !== template[key]) {
            isChanged = true;
            break;
          }
        }
        if(isChanged) {
          return true;
        }
       
        return isChanged;
      }

      if(checkDataChange()) {
        this.state.confirmUnsavedChangeShow = true;
      } else {
        this.scheduleRedirect();
      }
      
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    addNote() {
      this.note = {
        text: null,
        identifier: null
      }
      this.state.noteShow = true;
    },
    editNote(id) {
      const found = this.notes.find(i => i.uuId == id);
      if (found != null) {
        this.note = cloneDeep(found);
        this.state.noteShow = true;
      } else {
        this.alertMsg = this.$t('unable_to_open_detail', ['entityType.NOTE']);
      }
    },
    removeNote(id) {
      const index = this.notes.findIndex(i => i.uuId == id);
      if (index != -1) {
        this.notes.splice(index, 1);
      }
    },
    toAddNote(payload) {
      payload.uuId = `NEW_NOTE_${strRandom(5)}`;
      this.notes.unshift(payload);
    },
    toUpdateNote(payload) {
      const found = this.notes.find(i => i.uuId == payload.uuId);
      if (found != null) {
        for (const key of Object.keys(payload)) {
          found[key] = payload[key];
        }
      }
    },
    removeUnchangedTemplateProperties(data) {
      //Remove those properties whose value is not changed in provided data against original template.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalTemplate = this.originTemplate;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalTemplate[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    tagsModified({tags}) {
      this.tags = tags;
    }
  }
}
</script>
